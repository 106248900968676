<template>
  <div class="container flex">
    <div class="w-0 lg:py-14 lg:ml-4 lg:w-60">
      <ul class="relative">
        <li class="relative">
          <router-link to="/privacy-policy" class="flex items-center text-base font-bold text-yellow lg:py-2 h-12 overflow-hidden whitespace-nowrap">{{
            contents[activeIndex].sideBarComp[0]
          }}</router-link>
        </li>
        <li class="relative">
          <router-link to="/terms-conditions" class="flex text-base lg:py-2 h-12 overflow-hidden whitespace-nowrap">{{ contents[activeIndex].sideBarComp[1] }}</router-link>
        </li>
      </ul>
    </div>
    <div class="privacy_policy mx-4">
      <div class="container py-16 mdmax:py-10 text-justify mdmax:text-left">
        <div class="flex mdmax:flex-col justify-between mdmax:mb-4 mb-7">
          <div class="w-3/4 mdmax:w-full">
            <router-link to="/" class="text-base font-normal text-yellow cursor-pointer">
              <span class="pr-1">
                <Chevron direction="left" width="10" height="10" color="#F7931E" />
              </span>
              {{ contents[activeIndex].backToHome }}
            </router-link>
            <div class="w-full hidden mdmax:pt-3 mdmax:inline-block">
              <div class="text-xs font-normal text-neutral-500 mb-2">{{ contents[activeIndex].selections.selectLang }}</div>
              <div class="relative">
                <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-10">
                  <Chevron direction="down" width="16" height="16" color="#F7931E" />
                </span>
                <select
                  v-model="activeIndex"
                  class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full h-10 text-xs rounded-lg appearance-none bg-white relative z-1"
                >
                  <option :value="index" v-for="(item, index) in language" :key="'language1-' + index">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text-3.5xl mdmax:text-2xl font-bold text-neutral mdmax:pt-4 pt-6">{{ contents[activeIndex].staticText.title }}</div>
          </div>
          <div class="w-1/4 mdmax:hidden">
            <div class="text-xs font-normal text-neutral-500 mb-2">{{ contents[activeIndex].selections.selectLang }}</div>
            <div class="relative w-full">
              <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-10">
                <Chevron direction="down" width="16" height="16" color="#F7931E" />
              </span>
              <select v-model="activeIndex" class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg appearance-none bg-white relative z-1">
                <option :value="index" v-for="(item, index) in language" :key="'language2-' + index">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="text-base mdmax:text-sm font-normal text-neutral-500 pb-4" v-for="(bodyText, index) in contents[activeIndex].staticText.body" :key="'body-' + index">
          {{ bodyText }}
        </div>
        <div class="text-lg mdmax:text-base font-semibold text-neutral-500 pb-4">{{ contents[activeIndex].loopTextTitle }}</div>
        <div class="pb-4" v-for="(content, index) in contents[activeIndex].loopingText" :key="'loopingText-' + index">
          <div class="text-lg mdmax:text-base font-semibold text-neutral-500">{{ content.title }}</div>
          <div class="text-base mdmax:text-sm font-normal text-neutral-500">
            {{ content.body1 }}
          </div>
          <div class="text-base mdmax:text-sm font-normal text-neutral-500 pt-3" v-if="content.body2">
            {{ content.body2 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'privacy-policy',
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  data: () => ({
    activeIndex: 0,
    language: ['Bahasa Indonesia', 'English'],
    contents: [
      {
        staticText: {
          title: 'Kebijakan Privasi',
          body: [
            `Kebijakan Privasi ini hanya berlaku untuk layanan G2Academy. Kebijakan Privasi ini ditujukan untuk memberikan informasi kepada anda/pengguna layanan G2academy mengenai cara G2Academy
        mengumpulkan, menggunakan, membagikan, memproses dan mengamankan Data Pribadi Pengguna untuk layanan G2Academy. Dengan menggunakan layanan G2Academy, Pengguna memahami bahwa G2Academy akan
        menggunakan informasi Pengguna sebagaimana dijelaskan dalam Kebijakan Privasi ini.`,

            ` G2Academy akan memberikan pemberitahuan kepada Pengguna jika terjadi perubahan pada Kebijakan Privasi ini dengan mempostingnya pada laman ini dan/atau menuliskan tanggal pembaharuan kebijakan
        Privasi terakhir.`
          ]
        },
        loopTextTitle: 'Informasi yang dihimpun oleh G2Academy',
        selections: {
          selectLang: 'Pilih Bahasa'
        },
        sideBarComp: ['Kebijakan Privasi', 'Syarat & Ketentuan'],
        backToHome: 'Kembali ke Beranda',
        loopingText: [
          {
            title: 'Data yang kami minta dari Anda',
            body1: `Ketika Pengguna menggunakan website kami, Pengguna dapat memasukkan informasi pribadi
        berupa e-mail, nama dan informasi lainnya. Informasi yang Pengguna berikan selanjutnya kami
        kumpulkan untuk kebutuhan personalisasi Pengguna demi kebutuhan layanan yang kami berikan dan
        juga untuk meningkatkan kualitas layanan kami secara keseluruhan.`
          },
          {
            title: 'Cookies',
            body1: `Kami akan menggunakan Cookies selama pengguna menggunakan Layanan, refrensi Pengguna dan data
        autentikasi terkait sesi pengguna di dalam browser. Murid dan kami tidak menggabungkan informasi
        umum yang kami dapatkan melalui cookies dengan data pribadi yang telah kami dapatkan. Untuk mendapatkan
        pengalaman yang lebih baik dalam menggunakan layanan dan website kami, maka kami menyarankan Pengguna untuk
        tetap mengaktifkan fitur cookie tersebut.`
          },
          {
            title: 'Penggunaan Data Pribadi dan Informasi Murid G2Academy',
            body1: `Informasi Pribadi dan Informasi Pribadi Murid G2Academy adalah bagian penting dalam bisnis yang kami
        jalankan. Kami tidak menyewakan dan/atau menjual data Pengguna kepada siapapun. Perlu dipahami dan diperhatikan
        bahwa Informasi Pribadi adalah untuk pengguna yang bukan murid, dan Informasi Murid G2Academy adalah data pribadi
         untuk para pengguna yang telah mendaftarkan diri secara resmi menjadi Murid G2Academy.`
          },
          {
            title: 'Keamanan Data Pribadi',
            body1: `Kami melakukan upaya-upaya pengamanan dan penyimpanan dengan penuh kehati-hatian guna melindungi kerahasiaan
        data pribadi Pengguna dari waktu ke waktu. Setiap konten yang diunggah dan dikirim oleh Pengguna ke Layanan Kami akan
        tersimpan secara aman dan terkirim secara rahasia dengan menggunakan standard pengamanan informasi elektronik industri.`
          },
          {
            title: 'Permohonan Perubahan',
            body1: `Pengguna berhak mengubah Data Pribadi Pengguna yang disimpan di G2Academy. Perubahan, penambahan, atau pembaharuan
        informasi dan data pribadi tersebut dapat dilakukan dengan menyampaikan pemberitahuan kepada G2Academy melalui Kontak G2Academy.
        G2Academy berhak melakukan verifikasi terhadap perubahan, penambahan atau pembaharuan informasi dan data pribadi tersebut sesuai
        dengan prosedur G2Academy.`
          },
          {
            title: 'Penghapusan Data Pribadi',
            body1: `Atas permintaan Pengguna, G2Academy akan menghapus Data Pribadi Pengguna dari sistem G2Academy.
        Permohonan terhadap Hak Pengguna terkait Penghapusan Data Pribadi dan pertanyaan lebih lanjut mengenai G2Academy dalam menjalankan
        Kebijakan Privasinya dapat disampaikan melalui pemberitahuan dari Anda kepada Kami dengan melalui e-mail yang ditujukan kepada legal@g2academy.co`
          },
          {
            title: 'Pertanyaan',
            body1: `Jika anda memiliki pertanyaan seputar privasi di G2academy.co, anda dapat mengirimkan pesan dengan detil yang anda perlukan ke legal@g2academy.co.
        Kami akan segera menjawab setap pertanyaan anda`
          }
        ]
      },
      {
        staticText: {
          title: 'Privacy Policy',
          body: [
            `This Privacy Policy applies to all of G2Academy’s services. It is intended to explain how we collect, use, share, process and secure
        your Personal Data. By using our services, you understand that G2Academy will utilize collected information as described within thisPrivacy Policy.`,

            `We may periodically update our policy and will notify you of any significant changes being made. The most current version will be
        posted on this page along with the date of the last Privacy Polic update. Our aim is not just to comply with privacy law, but to also earn your trust.`
          ]
        },
        loopTextTitle: 'Information collected by G2Academy',
        selections: {
          selectLang: 'Select Language'
        },
        sideBarComp: ['Privacy Policy', 'Terms & Conditions'],
        backToHome: 'Back to Home',
        loopingText: [
          {
            title: 'Data You Provide Us',
            body1: `Within our website, information is shared directly to us when you
            create an account, fill out a form, submit/post content through our
            services, request customer support, or otherwise communicate with
            G2Academy.`,
            body2: `The types of personal data we may gather includes your name, email
            address, business information, and other data you choose to provide.G2Academy
            uses this information to better optimize our services and cater to your personalized
            needs.`
          },
          {
            title: 'Cookies',
            body1: `Cookies will be used for as long as you are using G2Academy’s
            services, user reference and authentication data related to your
            session in the browser. G2Academy will not combine the general
            information collected through cookies with the personal data and
            student information gathered. To get a better experience when
            using our services and website, we advise that you keep the cookie
            feature enabled.`
          },
          {
            title: 'Use of Personal Data and Information From G2Academy Students',
            body1: `User’s personal data and G2Academy student Information are
            important to us. We do not rent and/or sell this data to anyone. User’s personal
            data and G2Academy student Information are important to us. We do not rent and/or
            sell this data to anyone.`
          },
          {
            title: 'Security of Personal Data',
            body1: `We take all possible security measures and storage efforts to protect
            the confidentiality of your personal data. Every content uploaded and sent by you to
            our services will be stored securely and delivered confidentially using the industry’s
            security standards for electronic information.`
          },
          {
            title: 'Modifying Your Personal Data',
            body1: `You have the right to modify your Personal Data stored in G2Academy. Changes,
            additions, or updates can be made by submitting a formal request through our contact
            information. In accordance with G2Academy procedures, we have a right to verify these
            changes, additions or updates before implementation.`
          },
          {
            title: 'Deleting Personal Data',
            body1: `Upon your request, we can delete all of your personal data from G2Academy’s system.
            This request, along with further inquiries about G2Academy’s privacy policy, can be sent to
            legal@g2academy.co.`
          },
          {
            title: 'Contact Us',
            body1: `If you have any questions about this privacy policy, please contact us at legal@g2academy.co.
            We will respond to your inquiries as soon as possible.`
          }
        ]
      }
    ]
  }),
  computed: {
    ...mapGetters('common', ['contentLanguage'])
  },
  watch: {
    activeIndex: function (value) {
      let name = 'ina'
      value === 0 ? (name = 'ina') : (name = 'en')
      this.setContentLanguage({ id: value, name })
    }
  },
  mounted() {
    this.activeIndex = this.contentLanguage.id
  },
  methods: {
    ...mapMutations('common', ['setContentLanguage'])
  }
}
</script>
